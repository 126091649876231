import axios from 'axios';
import getApiUrl from '../../helpers/apiUrls'
import { authHeader } from '../../helpers/authHeader';
import utilCommon, {
    prettifyProductsHazmatValues,
    waitUntil,
} from '../../helpers/utilCommon';
import * as actions from '../../store/shipment/actions'
import * as commonAction from '../../store/common/actions'
import * as type from '../../store/shipment/actionTypes'
import * as quoteType from '../../store/quote/actionTypes'
import saveAs from 'file-saver'
import customerService, {
    getARPaymentOptions
} from '../../services/api/customerService'
import {reset} from "redux-form";
import lodash from 'lodash'
import moment from "moment";
import {
    INSURANCE_QUOTE_EXPIRES_IN_HOURS,
    LTL_SHIPMENT_MODE,
    TL_SHIPMENT_MODE,
    VIEWPORT_BASIC_SECTION, VIEWPORT_PRODUCT_SECTION,
} from '../../helpers/enums';
import * as globals from "../../helpers/enums";
import { getTlTrackingData } from '@services/V2/shipment/Tl/service';
import storeCreditService from "@services/storeCreditService";

export const getTeams = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };

    let url = getApiUrl('applicationSettings', 'teams');

    let queryString = '';

    if (inputDTO) {
        queryString = utilCommon.convertObjectToQueryString(inputDTO);
    }

    if (queryString) {
        url += `?${queryString}`;
    }

    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_TEAMS, teams: response.data.content.teamsList })
            }
        })
        .catch((err) => {

        })
}

export const getMasterData = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    // agents

    let url2 = getApiUrl('customer', 'agents');
    axios.get(url2, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_AGENTS, agents: response.data.content.userList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});

    const url22 = getApiUrl('quote', 'getFaciltyTypes');
    // Facility Types
    axios.get(url22, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: quoteType.SET_FACILITY_TYPES, facilityTypes: response.data.content.facilityTypeAccessorialsList })
            }
        })
        .catch((err) => {});

    const params3 = { headers: authHeader() };
    const url3 = getApiUrl('shipment', 'getAccessorial');
    axios.get(url3, params3)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_ACCESSORIALS, accessorials: response.data.content.accessorialList })
            }
        })
        .catch((err) => {});
    const params4 = { headers: authHeader() };
    const url4 = getApiUrl('shipment', 'facility');
    axios.get(url4, params4)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_FACILITY_TYPE, facilityList: response.data.content.loadingFacilityList })
            }
        })
        .catch((err) => {});
    const params5 = { headers: authHeader() };
    const url5 = getApiUrl('customer', 'agents');
    axios.get(url5 + "?status=1&sortColumn=name&sortOrder=asc", params5)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_ACTIVE_AGENTS, activeAgents: response.data.content.userList })
            }
        })
        .catch((err) => {});
    const params6 = { headers: authHeader() };
    const url6 = getApiUrl('customer', 'customerMaster');
    axios.get(url6, params6)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_SHIPMENT_MASTER, shipmentMasterData: response.data.content.compositeCustomerDataList })
            }
        })
        .catch((err) => {});
    const params7 = { headers: authHeader() };
    const url7 = getApiUrl('shipment', 'master');
    axios.get(url7, params7)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_MASTER_DATA, masterData: response.data.content.masterDataList })
            }
        })
        .catch((err) => {});
    const params8 = { headers: authHeader() };
    let url8 = getApiUrl('applicationSettings', 'teams');

    if (inputDTO && inputDTO.teamsQuery) {
        const teamsQueryString = utilCommon.convertObjectToQueryString(inputDTO.teamsQuery);
        url8 += `?${teamsQueryString}`;
    }

    axios.get(url8, params8)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_TEAMS, teams: response.data.content.teamsList })
            }
        })
        .catch((err) => {});
        // carrier
    const params9 = { headers: authHeader() };
    const url9 = getApiUrl('carrier', 'getCarriers')

    axios.get(url9, params9)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_CARRIERS, carriers: response.data.content.carrierList })
            }
        })
        .catch((err) => {});

    // Contracts
    const params10 = {headers: authHeader()};
    const url10 = getApiUrl('contract', 'generalInformation') + '?shipmentModeId=' + lodash.get(inputDTO, 'shipmentModeId')

    axios.get(url10, params10)
        .then((response) => {
            if (response.data.success) {
                dispatch({type: type.SET_CONTRACTS, contracts: response.data.content.contractList})
            }
        })
        .catch((err) => {
        });
};


// get shipment list
export const getShipments = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'shipment');

    let col = inputDTO.sortColumn
    if (inputDTO.sortColumn == 'customerFirstName' || inputDTO.sortColumn == 'customerLastName') {
        col = 'customerName'
    }

    url = url + '?pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + col + '&sortOrder=' + inputDTO.sortOrder
    if (inputDTO.keyword) {
        url = url + '&keyword=' + (inputDTO.keyword.includes('&') ? inputDTO.keyword.split('&')[0] + encodeURIComponent('&') + inputDTO.keyword.split('&')[1] : inputDTO.keyword)
    }

    Object.keys(inputDTO).filter(x => x != 'advancedSearch').map((y, index) => {
        const advancedSearchParams = [
            'shipmentUuid', 'proNumber', 'pickupNumber', 'customerCompany', 'customerFirstName', 'customerLastName', 'customerEmailId',
            'shipmentFromCity', 'shipmentToCity', 'shipmentFromZip', 'shipmentToZip', 'shipmentFromStateId', 'shipmentToStateId'
        ];

        if (advancedSearchParams.indexOf(y) > -1) {
            url = url + '&' + y + '=' + inputDTO[y]
        }
    })

    if (inputDTO.status) {
        url = url + `&status=${inputDTO.status}`
    }
    if (inputDTO.customerUuid) {
        url = url + `&customerUuid=${inputDTO.customerUuid}`
    }

    if (inputDTO.pickupDate && inputDTO.pickupDate !== "0") {
        if (inputDTO.pickupDate.startDate) {
            url = url + '&actualPickupDateFrom=' + inputDTO.pickupDate.startDate
            url = url + '&actualPickupDateTo=' + inputDTO.pickupDate.endDate
        } else {
            url = url + '&actualPickupDate=' + inputDTO.pickupDate
        }
    }

    if (inputDTO.createdDate && inputDTO.createdDate !== "0") {
        if (inputDTO.createdDate.startDate) {
            url = url + '&createdDateFrom=' + inputDTO.createdDate.startDate
            url = url + '&createdDateTo=' + inputDTO.createdDate.endDate
        } else {
            url = url + '&createdDate=' + inputDTO.createdDate
        }
    }

    if (inputDTO.bookingDate && inputDTO.bookingDate !== "0") {
        if (inputDTO.bookingDate.startDate) {
            url = url + '&shipmentBookingDateFrom=' + inputDTO.bookingDate.startDate
            url = url + '&shipmentBookingDateTo=' + inputDTO.bookingDate.endDate
        } else {
            url = url + '&shipmentBookingDate=' + inputDTO.bookingDate
        }
    }

    if (inputDTO.cancelStartDate) {
        url = url + '&cancelDateFrom=' + inputDTO.cancelStartDate
        url = url + '&cancelDateTo=' + inputDTO.cancelEndDate
    }

    if (inputDTO.alert && inputDTO.alert !== "2") {
        url = url + '&shipmentAlert=' + inputDTO.alert
    }

    if (inputDTO.billingAdjustment && inputDTO.billingAdjustment !== "2") {
        url = url + '&billingAdjustment=' + inputDTO.billingAdjustment
    }

    if (inputDTO.shipmentStatus && inputDTO.shipmentStatus.filter(x => x != "0").length > 0) {
        url = url + '&shipmentStatusId=' + JSON.stringify(inputDTO.shipmentStatus.filter(x => x != "0"))
    }

    if (inputDTO.agentUuid && inputDTO.agentUuid.filter(x => x != "0").length > 0) {
        url = url + '&agentUserUuid=' + JSON.stringify(inputDTO.agentUuid)
    }

    if (inputDTO.agentUuidTeam && inputDTO.agentUuidTeam.filter(x => x != "0").length > 0) {
        url = url + '&agentUserUuidTeam=' + JSON.stringify(inputDTO.agentUuidTeam)
    }

    if (inputDTO.carrierUuid && inputDTO.carrierUuid.filter(x => x != 0).length > 0) {
        url = url + '&carrierUuid=' + JSON.stringify(inputDTO.carrierUuid);
    }

    if (inputDTO.chargesAssignedUuid && inputDTO.chargesAssignedUuid.filter(x => x !== "0").length > 0) {
        url = url + '&chargesAssignedUuid=' + JSON.stringify(inputDTO.chargesAssignedUuid)
    }

    if (inputDTO.chargesAssignedUuidTeam && inputDTO.chargesAssignedUuidTeam.filter(x => x !== "0").length > 0) {
        url = url + '&chargesAssignedUuidTeam=' + JSON.stringify(inputDTO.chargesAssignedUuidTeam)
    }

    if (inputDTO.created && inputDTO.created.filter(x => x != "0").length > 0) {
        let created = inputDTO.created.map((x, index) => parseInt(x))
        url = url + '&created=' + JSON.stringify(created.filter(x => x != "0"))
    }

    if (inputDTO.template && inputDTO.template.length > 0) {
        url = url + '&template=' + JSON.stringify(inputDTO.template);
    }

    if (inputDTO.shipmentMode && inputDTO.shipmentMode.filter(x => x !== "0").length > 0) {
        url = url + '&shippingModeId=' + JSON.stringify(inputDTO.shipmentMode.filter(x => x !== "0"))
    }
    let arr = ['chargesAssignedUuid','pageSize','pageNumber','sortOrder','sortColumn','shipmentMode','created','agentUuid','shipmentStatus','alert','bookingDate','createdDate','pickupDate','bookingStartDate', 'bookingEndDate','createdStartDate','createdEndDate']
    if(inputDTO.advancedSearchFilter){
        Object.keys(inputDTO).filter(x => x != 'advancedFilterSearch').map((y, index) => {
            if(!arr.includes(y) && inputDTO[y] ){
                url = url + '&' + y + '=' + inputDTO[y]
            }

        })
    }

    // redirection params
    let couponCodeSet = false;
    if(inputDTO.redirectionFilters && inputDTO.redirectionFilters.redirection) {
        if ((inputDTO.redirectionFilters.report==4 || inputDTO.redirectionFilters.report==6) && inputDTO.redirectionFilters.shipmentFromZip) {
            url = url + '&shipmentFromZip=' + inputDTO.redirectionFilters.shipmentFromZip
        }
        if ((inputDTO.redirectionFilters.report==5 || inputDTO.redirectionFilters.report==6) && inputDTO.redirectionFilters.shipmentToZip) {
            url = url + '&shipmentToZip=' + inputDTO.redirectionFilters.shipmentToZip
        }
        if ((inputDTO.redirectionFilters.report==1 || inputDTO.redirectionFilters.report==3) && inputDTO.redirectionFilters.shipmentFromStateId) {
            url = url + '&shipmentFromStateId=' + inputDTO.redirectionFilters.shipmentFromStateId
        }
        if ((inputDTO.redirectionFilters.report==2 || inputDTO.redirectionFilters.report==3) && inputDTO.redirectionFilters.shipmentToStateId) {
            url = url + '&shipmentToStateId=' + inputDTO.redirectionFilters.shipmentToStateId
        }
        if ((inputDTO.redirectionFilters.report==8) && inputDTO.redirectionFilters.nmfc){
            url = url + '&nmfc=' + inputDTO.redirectionFilters.nmfc
        }
        if ((inputDTO.redirectionFilters.report==7) && inputDTO.redirectionFilters.freightClass){
            url = url + '&freightClass=' + inputDTO.redirectionFilters.freightClass
        }
        if (inputDTO.redirectionFilters.status && inputDTO.redirectionFilters.status !== '0'){
            url = url + '&status=' + inputDTO.redirectionFilters.status
        }

        if (inputDTO.redirectionFilters.customerUuid && inputDTO.redirectionFilters.customerUuid.id) {
            url = url + `&customerUuid=${inputDTO.redirectionFilters.customerUuid.id}`
        }

        if (inputDTO.redirectionFilters.cancelDateTo) {
            url = url + `&cancelDateTo=${inputDTO.redirectionFilters.cancelDateTo}`
        }
        if (inputDTO.redirectionFilters.cancelDateFrom && inputDTO.redirectionFilters.cancelDateFrom) {
            url = url + `&cancelDateFrom=${inputDTO.redirectionFilters.cancelDateFrom}`
        }


        if( inputDTO.redirectionFilters.carrierUuid && inputDTO.redirectionFilters.carrierUuid!='0'){
            url = url + '&carrierUuid=' + inputDTO.redirectionFilters.carrierUuid
        }
        if( inputDTO.redirectionFilters.accessorials && Array.isArray(inputDTO.redirectionFilters.accessorials) && inputDTO.redirectionFilters.accessorials.filter(x=>x!=0).length>0){
            url = url + '&accessorials=' + JSON.stringify(inputDTO.redirectionFilters.accessorials.filter(x=>x!=0))
        }
        if (inputDTO.redirectionFilters.agentUuid && inputDTO.redirectionFilters.agentUuid.filter(x => x != "0").length > 0) {
            url = url + '&agentUserUuid=' + JSON.stringify(inputDTO.redirectionFilters.agentUuid)
        }
        if (inputDTO.redirectionFilters.chargesAssignedUuid && inputDTO.redirectionFilters.chargesAssignedUuid.filter(x => x != "0").length > 0) {
            url = url + '&chargesAssignedUuid=' + JSON.stringify(inputDTO.redirectionFilters.chargesAssignedUuid)
        }
        if (inputDTO.redirectionFilters.date && inputDTO.redirectionFilters.date!='0') {
            let createdDate = utilCommon.getDateRange(1,inputDTO.redirectionFilters.date,inputDTO.redirectionFilters.fromDate,inputDTO.redirectionFilters.toDate)
            url = url + '&createdDateFrom=' + createdDate.startDate
            url = url + '&createdDateTo=' + createdDate.endDate
        }
        if (inputDTO.redirectionFilters.bookingDate && inputDTO.redirectionFilters.bookingDate!='0') {
            let bookingDate = utilCommon.getDateRange(1,inputDTO.redirectionFilters.bookingDate,inputDTO.redirectionFilters.fromDate,inputDTO.redirectionFilters.toDate)
            url = url + '&shipmentBookingDateFrom=' + bookingDate.startDate
            url = url + '&shipmentBookingDateTo=' + bookingDate.endDate
        }
        if (inputDTO.redirectionFilters.shipmentStatus && inputDTO.redirectionFilters.shipmentStatus.length > 0) {
            url = url + '&shipmentStatusId=' + JSON.stringify(inputDTO.redirectionFilters.shipmentStatus)
        }

        if (inputDTO.redirectionFilters.billingAdjustment && inputDTO.redirectionFilters.billingAdjustment != "2") {
            url = url + '&billingAdjustment=' + inputDTO.redirectionFilters.billingAdjustment
        }

        if (inputDTO.redirectionFilters.template && inputDTO.redirectionFilters.template.length > 0) {
            url = url + '&template=' + JSON.stringify(inputDTO.redirectionFilters.template);
        }

        if (inputDTO.redirectionFilters.quoteDateFrom) {
            url = url + '&quoteDateFrom=' + inputDTO.redirectionFilters.quoteDateFrom;
        }

        if (inputDTO.redirectionFilters.quoteDateTo) {
            url = url + '&quoteDateTo=' + inputDTO.redirectionFilters.quoteDateTo;
        }

        if (inputDTO.redirectionFilters.couponCode) {
            url = url + '&couponCode=' + inputDTO.redirectionFilters.couponCode;
            couponCodeSet = true;
        }
    }

    if (!couponCodeSet && !lodash.isEmpty(lodash.get(inputDTO, 'couponCode'))) {
        url = url + '&couponCode=' + inputDTO.couponCode;
    }

    if (lodash.get(inputDTO, 'customerId')) {
        url = url + '&customerId=' + JSON.stringify(inputDTO.customerId)
    }

    if (!lodash.get(inputDTO, 'liveCustomers', false)) {
        dispatch(commonAction.setLoader(true));
    }

    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                if (!lodash.get(inputDTO, 'liveCustomers', false)) {
                    if (!response.data.content.count) {
                        utilCommon.showNotification(9001, dictionary, lang)
                        dispatch(actions.setShipmentList([], 0, false))
                        return;
                    }

                    if (inputDTO.keyword) {
                        dispatch({type: type.SET_SEARCH, keyword: inputDTO.keyword})
                    } else {
                        dispatch({type: type.SET_SEARCH, keyword: null})
                    }
                    if (url.includes('&customerUuid')) {
                        dispatch({type: type.SET_CSP_URL, cspURL: url})
                    } else {
                        dispatch({type: type.SET_SHIPMENT_URL, shipmentURL: url})
                    }
                    dispatch(actions.setShipmentList(response.data.content.shipmentList, response.data.content.count, false))
                } else {
                    let shipmentlistByCustomer = {};
                    let latestShipmentByCustomer = [];
                    let count = 0;
                    response.data.content.shipmentList.map(
                        shipment => {
                            if (lodash.get(shipmentlistByCustomer, shipment.customerUuid.id, []).length == 0) {
                                shipmentlistByCustomer[shipment.customerUuid.id] = [];
                            }

                            shipmentlistByCustomer[shipment.customerUuid.id].push(shipment);
                        });
                    Object.keys(shipmentlistByCustomer).map(customerUuid => {
                        latestShipmentByCustomer.push(lodash.last(lodash.sortBy(shipmentlistByCustomer[customerUuid], ['addedDate'])));
                        count++;
                    })
                    dispatch(actions.setOnlineCustomerShipmentList(
                        latestShipmentByCustomer,
                        count
                    ))
                }
            } else {
                response.data.errors.map((error) => {
                    if (!lodash.get(inputDTO, 'liveCustomers', false)) {
                        utilCommon.showNotification(error, dictionary, lang)
                    }
                })

                if (!lodash.get(inputDTO, 'liveCustomers', false)) {
                    dispatch(actions.setShipmentList([], 0, false))
                }

                dispatch(actions.setOnlineCustomerShipmentList([], 0));
            }
        })
        .finally(() => {
            dispatch(commonAction.setLoader(false))
        });
};
//get getAddresses
export const getAddresses = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'address');
    let col = inputDTO.sortColumn
    url = url + '?customerUuid=' + inputDTO.customerUuid + "&sortColumn=addedDate&sortOrder=desc";

    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.customerAddressList && response.data.content.customerAddressList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setAddresses(response.data.content.customerAddressList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});
};


export const getShipmentLogs = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'shipmentLogs');
    let queryString = utilCommon.convertObjectToQueryString(inputDTO);
    url = url + `?${queryString}`;
    // dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            // dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_SHIPMENT_LOGS, logs: [], totalResults: 0 })
                dispatch({ type: type.SET_SHIPMENT_LOGS, logs: response.data.content.activityLog, totalResults: response.data.content.totalResults })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get Products for product book

export const getProducts = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'product');

    let col = inputDTO.sortColumn

    url = url + '?customerUuid=' + inputDTO.customerUuid + "&sortColumn=addedDate&sortOrder=desc";

    axios.get(url, params)
        .then((response) => {

            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.customerProductList && response.data.content.customerProductList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setProducts(response.data.content.customerProductList, response.data.content.count, false))

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});
};

// get shipment data


export const getShipmentData = (dispatch, lang, dictionary, inputDTO, flag,getTask) => {
    let products = '';
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'shipment');
    dispatch(commonAction.setLoader(true))
    if(inputDTO && inputDTO.updatedProducts){
        products = inputDTO.updatedProducts;
        delete inputDTO.updatedProducts;
    }
    if(!inputDTO.updateProductInfo){
        if(inputDTO && !inputDTO.firstTime){
            const dispatchData = { type: type.SET_UPDATED_PRODUCTS, updatedProductsFlag: true };
            if (products){
                dispatchData.updatedProducts = products;
            }
            dispatch(dispatchData);
        }
    }
    axios.get((url + "?shipmentUuid=" + inputDTO.shipmentUuid + '&maintainHash=1'), params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if(inputDTO.firstTime){
                    dispatch(actions.setCurrentShipment(response.data.content.shipmentList[0], response.data.content.count, false))
                    customerService.getARPaymentOptions(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,permFlag:true  })
                    if (!flag) {
                        getShipmentProducts(
                            dispatch,
                            lang,
                            dictionary,
                            { shipmentUuid: inputDTO.shipmentUuid },
                            _=>{
                                dispatch(reset('ShipmentForm'))
                            });
                        getProducts(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id })
                        customerService.getPaymentOptions(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,permFlag:true })
                        customerService.getCustomerData(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,permFlag:true  })
                        getContractCarrierDetails(dispatch, lang, dictionary, { carrierUuid: response.data.content.shipmentList[0].carrierUuid.id })
                        getAddresses(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id })
                        getFalveyTermsFlag(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id })
                        getTermsAndConditionFlag(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id })
                        getTasks(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,shipmentUuid: inputDTO.shipmentUuid })
                    }
                    if(getTask){
                        getTasks(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,shipmentUuid: inputDTO.shipmentUuid })
                    }
                } else {
                    dispatch(actions.setCurrentShipment(response.data.content.shipmentList[0], response.data.content.count, false))
                    if(inputDTO.makeShipmentPayment){
                        customerService.getARPaymentOptions(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,permFlag:true  })
                    }
                    if (!flag) {
                        if(inputDTO.makeShipmentPayment){
                            customerService.getPaymentOptions(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,permFlag:true })
                            customerService.getCustomerData(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,permFlag:true  })
                        }
                        getAddresses(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id })
                        if(inputDTO.addOrUpdateProduct || inputDTO.deleteProduct || inputDTO.updateProductInfo){
                            if (!lodash.get(inputDTO, 'processPayment', false)) {
                                getShipmentProducts(dispatch, lang, dictionary, { shipmentUuid: response.data.content.shipmentList[0].shipmentUuid })
                            }
                        }
                        // getFalveyTermsFlag(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id })
                        // getTermsAndConditionFlag(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id })
                        // getTasks(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,shipmentUuid: inputDTO.shipmentUuid })

                    }
                    if(getTask){
                        getTasks(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id,shipmentUuid: inputDTO.shipmentUuid })
                    }
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

const updateProductsQueue = async (dispatch, getState) => {
    const uniqId = utilCommon.getUniqId('product_');
    dispatch(actions.addRunnerProduct(uniqId));
    await waitUntil(_ => {
        const products = getState().shipment.runnerProducts;
        let allowed = false;
        products.forEach(item=>{
            if (item.id === uniqId && item.state ==='ALLOWED'){
                allowed = true;
            }
        })
        return allowed;
    });
    dispatch(actions.runRunnerProduct(uniqId));

    return uniqId;
}

 //1
// update Detailed Information
export const updateShipmentInfo = (lang, dictionary, inputDTO, callback) => async (dispatch, getState) =>  {
    const uniqId = await updateProductsQueue(dispatch, getState);

    const params = { headers: authHeader() };
    inputDTO.updateShipmentInfo = true;
    const url = getApiUrl('shipment', 'updateShipmentInfo');
    dispatch(actions.setLoader(true))
    typeof callback=='function'?callback(1):'';
    return axios[inputDTO.shipmentUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(actions.setLoader(false))
            typeof callback == 'function' ? callback(0) : '';
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getShipmentData(dispatch, lang, dictionary, inputDTO)
                getTransactions(dispatch, lang, dictionary, inputDTO)
                getTransactionDetails(dispatch, lang, dictionary, inputDTO)
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    if (error=='12520') {
                        let userName = 'someone'
                        if(response.data.content && response.data.content.userUuid){
                            userName = `${response.data.content.firstName} ${response.data.content.lastName}`
                        }
                        utilCommon.showNotification(`This shipment has been modified by ${userName}. Please refresh the shipment to ensure accurate information.`, dictionary, lang,true)
                    } else {
                        utilCommon.showNotification(error, dictionary, lang)
                    }
                })
            }
        })
        .catch((err) => {
            dispatch(actions.setLoader(false))
        }).finally(function(){
            dispatch(actions.doneRunnerProduct(uniqId));
        });;
};

// 2
//  add or  update note
export const addNote = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'note');
    dispatch(commonAction.setLoader(true))
    axios[inputDTO.customerNoteUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getNotes(dispatch, lang, dictionary, inputDTO);
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
// get Notes List
export const getNotes = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'note');
    let col = inputDTO.sortColumn
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid

    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.shipmentNoteList && response.data.content.shipmentNoteList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setNotes(response.data.content.shipmentNoteList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});
};

// update pin or unpin note
//3
export const pinOrUnpinNote = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'note');
    dispatch(commonAction.setLoader(true))
    axios['patch'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getNotes(dispatch, lang, dictionary, { ...inputDTO });
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
//4
//reassign shipments
export const resassignShipments = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'shipment');
    dispatch(commonAction.setLoader(true))
    axios['patch'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                delete inputDTO.shipmentUuid;
                dispatch({ type: type.SET_REASSIGN_FLAG, reassignFlag: true })
                getShipments(dispatch, lang, dictionary, { ...inputDTO });
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// add  product
export const addProduct = (dispatch, lang, dictionary, inputDTO, callback = null) => {
    const params = { headers: authHeader() };
    inputDTO.addOrUpdateProduct = true;
    const url = getApiUrl('shipment', 'product');
    dispatch(commonAction.setLoader(true))
    const method = inputDTO.shipmentProductUuid ? 'put' : 'post';
    axios[method](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                if (method === 'post') {
                    inputDTO.shipmentProductAdded = true;
                }
                getShipmentProducts(dispatch, lang, dictionary, inputDTO, callback)
                getShipmentData(dispatch, lang, dictionary, inputDTO)
                if (inputDTO.addToProductBook) {
                    getProducts(dispatch, lang, dictionary, { ...inputDTO, pageNumber: 1, pageSize: 10 });
                }
                getAddresses(dispatch, lang, dictionary, { customerUuid: response.data.content.shipmentList[0].customerUuid.id })
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export const addTLProduct = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    inputDTO.addOrUpdateProduct = true;
    const url = getApiUrl('shipment', 'product');
    dispatch(commonAction.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get Shipment Products for items section

export const getShipmentProducts = (dispatch, lang, dictionary, inputDTO, callback = null) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'product');

    let col = inputDTO.sortColumn

    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid + "&sortOrder=asc&sortColumn=addedDate&maintainHash=1";
    dispatch({ type: type.SET_UPDATED_PRODUCTS, updatedProductsFlag: false })
    axios.get(url, params)
        .then((response) => {
          if (response.data.success) {
            for (let i = 0; i < response.data.content.shipmentProductList.length; i++) {
              response.data.content.shipmentProductList[i].itemWeight = Math.ceil(response.data.content.shipmentProductList[i].itemWeight);
            }
              const shipmentProductAdded = lodash.get(inputDTO, 'shipmentProductAdded', false);
              const shipmentProductDeleted = lodash.get(inputDTO, 'shipmentProductDeleted', false);
              dispatch(actions.setShipmentProducts(
                  prettifyProductsHazmatValues(response.data.content.shipmentProductList),
                  response.data.content.count, shipmentProductAdded, shipmentProductDeleted));
            typeof callback === 'function' ? callback(response.data.content.shipmentProductList) : '';
          } else {
            response.data.errors.map((error) => {
              utilCommon.showNotification(error, dictionary, lang);
            });
          }
        })
        .catch((err) => {});
};
// 5
//  delete Product
export const deleteProduct = (lang, dictionary, inputDTO, callback = null) => async (dispatch, getState) =>{

    console.log('state', 'deleteProductInit');
    const uniqId = await updateProductsQueue(dispatch, getState);
    console.log('state', 'deleteProductStart');

    const params = { headers: authHeader() };
    inputDTO.deleteProduct = true;
    const url = getApiUrl('shipment', 'product');
	dispatch(commonAction.setLoader(true))
    return axios.patch(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                inputDTO.shipmentProductDeleted = true;
                getShipmentProducts(dispatch, lang, dictionary, inputDTO, callback);
                getShipmentData(dispatch, lang, dictionary, inputDTO);
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        }).finally(function(){
            dispatch(actions.doneRunnerProduct(uniqId));
        });
};

export const deleteTLProduct = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    inputDTO.deleteProduct = true;

    const url = getApiUrl('shipment', 'product');

    dispatch(commonAction.setLoader(true))

    return axios.patch(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
};
// update products
// 6
export const updateProductInfo =  (lang, dictionary, inputDTO, callback) => async (dispatch, getState) => {
    const uniqId = await updateProductsQueue(dispatch, getState);
    const params = { headers: authHeader() };
    inputDTO.updateProductInfo = true;
    const url = getApiUrl('shipment', 'product');
    dispatch(actions.setLoader(true))
    typeof callback=='function'?callback(1):'';
    dispatch({ type: type.SET_UPDATED_PRODUCTS, updatedProductsFlag: false })
    return axios['put'](url, inputDTO, params)
        .then((response) => {
            dispatch(actions.setLoader(false))
            typeof callback=='function'? callback(0) : '';
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getShipmentProducts(dispatch, lang, dictionary, inputDTO)
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            // If re-run is not required & insurance exists & product(s) commodity or condition were changed let's cancel and add it again
            // If re-run is required and insurance exists it will be done upon re-run
            let insuranceTransactionUuid = lodash.get(inputDTO, 'insuranceTransaction.shipmentTransactionUuid', 0)
            let insuranceQuoteExpired = insuranceTransactionUuid && lodash.get(inputDTO, 'allowUpdateInsurance')
            && !lodash.get(inputDTO, 'shipmentReRunQuote')
            && !lodash.get(inputDTO, 'shipmentInfoReRunQuote')
            && !lodash.get(inputDTO, 'reRunQuote')
                ? (![globals.SHIPPING_PICKUP_STATUS, globals.SHIPPING_IN_TRANSIT_STATUS, globals.SHIPPING_DELIVERED_STATUS]
                    .includes(lodash.get(inputDTO, 'shipmentStatusId'))
                    ? moment(lodash.get(inputDTO, 'insuranceTransaction.addedDate')).utc().add(INSURANCE_QUOTE_EXPIRES_IN_HOURS, 'hours').isBefore(moment().utc())
                    : false)
                : false;
            let updateInsurance = lodash.get(inputDTO, 'allowUpdateInsurance')
                && !lodash.get(inputDTO, 'shipmentReRunQuote')
                && !lodash.get(inputDTO, 'shipmentInfoReRunQuote')
                && !lodash.get(inputDTO, 'reRunQuote')
                && lodash.get(inputDTO, 'commodityOrConditionChanged')
            if (insuranceTransactionUuid && (insuranceQuoteExpired || updateInsurance)) {
                let cancelInsurancePayload = {
                    ...inputDTO,
                    shipmentTransactionUuid: insuranceTransactionUuid,
                    customerUuid: lodash.get(inputDTO, 'customer.id'),
                    addInsurance: false, // Always false as we never have the case where we need to re-add the insurance
                    doInsurance: [globals.SHIPPING_PICKUP_STATUS, globals.SHIPPING_IN_TRANSIT_STATUS, globals.SHIPPING_DELIVERED_STATUS]
                        .includes(lodash.get(inputDTO, 'shipmentStatusId'))
                }
                cancelInsurance(dispatch, lang, dictionary, cancelInsurancePayload)
            }

            if (isGetShipmentDataRequired(inputDTO)) {
                getShipmentData(dispatch, lang, dictionary, {shipmentUuid: inputDTO.shipmentUuid});
            }
            } else {
                response.data.errors.map((error) => {
                    if (error == '12521') {
                        let userName = 'someone'
                        if(response.data.content && response.data.content.userUuid){
                            userName = `${response.data.content.firstName} ${response.data.content.lastName}`
                        }
                        utilCommon.showNotification(`This shipment has been modified by ${userName}. Please refresh the shipment to ensure accurate information.`, dictionary, lang)
                    } else{
                        utilCommon.showNotification(error, dictionary, lang)
                    }
                })
            }
        })
        .catch((err) => {
            dispatch(actions.setLoader(false))
        }).finally(function(){
            dispatch(actions.doneRunnerProduct(uniqId));
        });
    ;
};

const isGetShipmentDataRequired = data => {
    const autoSaveDirtySections = lodash.get(data, 'autoSaveDirtySections', {
        [VIEWPORT_BASIC_SECTION]: false, [VIEWPORT_PRODUCT_SECTION]: false
    });

    return !autoSaveDirtySections[VIEWPORT_BASIC_SECTION] && autoSaveDirtySections[VIEWPORT_PRODUCT_SECTION];
}

// get shipment alert
export const getShipmentAlert = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'alert');
    url = url + "?shipmentUuid=" + inputDTO.shipmentUuid
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                if (response.data.content.shipmentAlertList && response.data.content.shipmentAlertList[0]) {
                    dispatch(actions.setShipmentAlert(response.data.content.shipmentAlertList[0], response.data.content.count, false))
                }
                else {
                    dispatch(actions.setShipmentAlert({}, response.data.content.count, false))
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});
}

// add or update shipment alert
// 7
export const addOrUpdateAlert = (dispatch, lang, dictionary, inputDTO, type = LTL_SHIPMENT_MODE) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'alert');
    dispatch(commonAction.setLoader(true))
    axios[inputDTO.shipmentAlertUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                if ( type === LTL_SHIPMENT_MODE) {
                    getShipmentAlert(dispatch, lang, dictionary, inputDTO)
                    if(inputDTO.shipmentUuid) {
                        getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                    }
                }else{
                    getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
//8
//deactivate shipment

export const deactivateShipment = (dispatch, lang, dictionary, inputDTO, type = LTL_SHIPMENT_MODE) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'deactivate');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);

                if ( type === LTL_SHIPMENT_MODE) {
                    getShipmentData(dispatch, lang, dictionary, inputDTO, undefined, undefined)
                    getShipmentAlert(dispatch, lang, dictionary, inputDTO)

                    if(inputDTO.shipmentUuid) {
                        getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                    }
                }else{
                    getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
//9
//activate shipment
export const activateShipment = (dispatch, lang, dictionary, inputDTO, type = LTL_SHIPMENT_MODE) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'activate');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                if ( type === LTL_SHIPMENT_MODE) {
                    getShipmentData(dispatch, lang, dictionary, inputDTO, undefined, undefined)
                    if(inputDTO.shipmentUuid) {
                        getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                    }
                }else{
                    getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};


// shipment payment
export const makeShipmentPayment = (dispatch, lang, dictionary, inputDTO, cb = null, type = LTL_SHIPMENT_MODE) => {
    const params = { headers: authHeader() };
    inputDTO.makeShipmentPayment = true;
    let url = ''
    if(inputDTO.refunds){
        url = getApiUrl('shipment', 'shipmentRefund');
    } else {
        url = getApiUrl('shipment', 'shipmentPayment');
    }

    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            if ( type === LTL_SHIPMENT_MODE ){
                getTransactions(dispatch, lang, dictionary, inputDTO)
                getTransactionDetails(dispatch, lang, dictionary, inputDTO)
                getPayments(dispatch, lang, dictionary, inputDTO)
                getARPaymentOptions(dispatch, lang, dictionary, inputDTO)
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)
            }else{
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
            }
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (response.data.content.data['3']) {
                    Object.keys(response.data.content.data['3']).map(x => {
                        if (response.data.content.data['3'][x].status) {
                            let cardDetails = inputDTO.payments.filter(y => y.paymentTypeId == '3').find(z => z.paymentProfileId == x).cardDetail
                            utilCommon.showNotification('Shipment payment with Card ' + cardDetails.type + " " + cardDetails.number + " saved", dictionary, lang)
                        } else {
                            if(inputDTO.refunds && response.data.message){
                                utilCommon.showNotification(response.data.message, dictionary, lang, false)
                            } else {
                                let cardDetails = inputDTO.payments.filter(y => y.paymentTypeId == '3').find(z => z.paymentProfileId == x).cardDetail
                                utilCommon.showNotification('Shipment payment with Card ' + cardDetails.type + " " + cardDetails.number + " failed", dictionary, lang, true)
                            }
                        }
                    })
                }

                if (response.data.content.data['4']) {
                    if (response.data.content.data['4'].length > 0 && response.data.content.data['4'][0].status) {
                        utilCommon.showNotification("Shipment payment with check saved", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with check failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['5']) {
                    if (response.data.content.data['5'].length > 0 && response.data.content.data['5'][0].status) {
                        utilCommon.showNotification("Shipment payment with wire transfer saved", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with wire transfer failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['6']) {
                    if (response.data.content.data['6'].length > 0 && response.data.content.data['6'][0].status) {
                        utilCommon.showNotification("Shipment payment with automated clearing house (ACH) saved", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with automated clearing house (ACH) failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['7']) {
                    if (response.data.content.data['7'].length > 0 && response.data.content.data['7'][0].status) {
                        utilCommon.showNotification("Shipment payment with A/R account saved", dictionary, lang)
                        if ((inputDTO.amountDue > inputDTO.arCreditLimit) && inputDTO.amountDue < ((inputDTO.arCreditLimit + inputDTO.arGraceAmount))) {
                            utilCommon.showNotification("The customer's credit limit has been exceeded. As a one-time courtesy, FreightCenter is processing this shipment", dictionary, lang)
                        }
                    } else {
                        utilCommon.showNotification("Shipment payment with A/R account failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['15']) {
                    if (response.data.content.data['15'].length > 0 && response.data.content.data['15'][0].status) {
                        utilCommon.showNotification("Shipment payment with Other Payment Method saved", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with Other Payment Method failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['16']) {
                    if (response.data.content.data['16'].length > 0 && response.data.content.data['16'][0].status) {
                        utilCommon.showNotification("Shipment payment with Store Credit Method saved", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with Store Credit Method failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['18']) {
                    if (response.data.content.data['18'].length > 0 && response.data.content.data['18'][0].status) {
                        utilCommon.showNotification("Shipment payment with Credit card - manually processed saved", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with Credit card - manually processed failed", dictionary, lang, true)
                    }
                }

                if ( type === LTL_SHIPMENT_MODE ) {
                    if (inputDTO.shipmentUuid) {
                        getShipmentLogs(dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                    }
                }

                if (typeof cb === 'function') {
                  cb();
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
                if(response.data.errors.includes('12515') ||  response.data.errors.includes('12517') || response.data.errors.includes(12515) ||  response.data.errors.includes(12517)){
                    if ( type === LTL_SHIPMENT_MODE ) {
                        getShipmentData(dispatch, lang, dictionary, inputDTO)
                    }else{
                        getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
                    }
                }
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
//
// get transactions for reassign list
export const getTransactions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'transaction');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid + "&sortColumn=[modifiedDate,shipmentDescription]&sortOrder=desc"

    axios.get(url, params)
        .then((response) => {

            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.shipmentTransactionList && response.data.content.shipmentTransactionList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setShipmentTransactions(response.data.content.shipmentTransactionList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
        });
};

// get transactions logs
export const getTransactionLogs = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'transactionLog');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid + "&sortColumn=modifiedDate&sortOrder=desc"
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.shipmentTransactionList && response.data.content.shipmentTransactionList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setShipmentTransactionLogs(response.data.content))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//transaction details for detail page
export const getTransactionDetails = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'transactionDetail');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid + "&sortColumn=modifiedDate&sortOrder=desc"

    axios.get(url, params)
        .then((response) => {

            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.shipmentTransactionDetail && response.data.content.shipmentTransactionDetail.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setShipmentTransactionDetails(response.data.content.shipmentTransactionDetail, response.data.totalCharge, response.data.totalCost, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
        });
};
//10
// reassign transaction charges
export const reassignTransaction = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'transaction');
    dispatch(commonAction.setLoader(true))
    axios['patch'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getTransactions(dispatch, lang, dictionary, inputDTO)
                getTransactionDetails(dispatch, lang, dictionary, inputDTO)
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};




// validate coupon code
export const validateCouponCode = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'coupon');
    // dispatch({ type: type.SET_COUPON_VALIDATE_FLAG, isCouponValid: true })
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('999998', dictionary, lang);
                dispatch(
                    {
                        type: type.SET_COUPON_VALIDATE_FLAG,
                        isCouponValid: true,
                        discountAmount: lodash.get(response, 'data.content.couponCodeList[0].discountAmount', 0),
                        discountType: response.data.content.couponCodeList[0].discountType,
                        maxDiscountAmount: lodash.get(response, 'data.content.couponCodeList[0].maxDiscountAmount', 0)
                    })
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
                dispatch(
                    {
                        type: type.SET_COUPON_VALIDATE_FLAG,
                        isCouponValid: false,
                        discountAmount: "0.00",
                        discountType: 1
                    })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// validate Insurance declared value
export const validateInsurance = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'validateInsurance');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('99997', dictionary, lang);
                dispatch({
                    type: type.SET_INSURANCE_VALIDATE_FLAG, isInsuranceValid: true,
                    insuranceAmount: (response.data.content ? response.data.content.InsurancePremium.replace(/,/g, '').replace(' ', '') : '0.00'),

                })
                dispatch({
                    type: type.SET_EXCLUDE_ITEM, insuranceExcludedId: response.data.content ? response.data.content.ExcludedItemIDMatch : '',
                    insuranceExcludedItemName: response.data.content ? response.data.content.ExcludedItemMatch : ''
                })



            } else {
                let error = lodash.get(response.data, 'errors.0');

                if (error) {
                    utilCommon.showNotification(error, dictionary, lang, true);
                } else {
                    utilCommon.showNotification('99996', dictionary, lang);
                }

                dispatch({ type: type.SET_INSURANCE_VALIDATE_FLAG, isInsuranceValid: false, insuranceAmount: '0.00', insuranceExcludedId: '', insuranceExcludedItemName: '' })
                dispatch({
                    type: type.SET_EXCLUDE_ITEM, insuranceExcludedId: '',
                    insuranceExcludedItemName: ''
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
//add transaction
export const addTransaction = (dispatch, lang, dictionary, inputDTO, flag) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'transaction');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if(flag){
                    utilCommon.showNotification('Coupon applied succesfully', dictionary, lang)
                }else{
                    utilCommon.showNotification(response.data.message, dictionary, lang);
                }
                getTransactions(dispatch, lang, dictionary, inputDTO)
                getTransactionDetails(dispatch, lang, dictionary, inputDTO)
                getPayments(dispatch, lang, dictionary, inputDTO)
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
            }

            getShipmentAlert(dispatch, lang, dictionary, inputDTO)
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//add tl transaction
export const addTLTransaction = (dispatch, lang, dictionary, inputDTO, flag) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'transaction');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if(flag){
                    utilCommon.showNotification('Coupon applied succesfully', dictionary, lang)
                }else{
                    utilCommon.showNotification(response.data.message, dictionary, lang);
                }
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
            }

            getShipmentAlert(dispatch, lang, dictionary, inputDTO)
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};



// get payment section list

export const getPayments = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'payment');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid + "&sortColumn=modifiedDate&sortOrder=desc";
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.shipmentPaymentList && response.data.content.shipmentPaymentList.length == 0) {
                    // utilCommon.showNotification(9001, dictionary, lang)
                }
                dispatch(actions.setShipmentPaymentDetails(response.data.content.shipmentPaymentList, response.data.totalAmount, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
        });
};


// repeat shipment
export const repeatShipment = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'repeatShipment');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, { shipmentUuid: inputDTO.shipmentUuid }, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                utilCommon.setSession('flipAllow', true);
                window.location.replace(`${process.env.REACT_APP_BASEPATH}/shipment-management/${response.data.content[0].shipmentUuid}`)
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//cancel insurance
// 11
export const cancelInsurance = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'insurance');
    dispatch(commonAction.setLoader(true))
    axios['patch'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)
                getTransactionDetails(dispatch, lang, dictionary, inputDTO);
                getTransactions(dispatch, lang, dictionary, inputDTO);
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }

                if (lodash.get(inputDTO, 'addInsurance', false)) {
                    let addInsurancePayload = {
                        ...inputDTO,
                        customerUuid: lodash.get(inputDTO, 'customer'),
                        transactionTypeId: {
                            id: 4,
                            parentTransactionTypeId: 0
                        },
                        insuranceDeclaredValue: lodash.get(inputDTO, 'insuranceTransaction.insuranceDeclaredValue'),
                        addInsurance: false,
                        doInsurance: lodash.get(inputDTO, 'doInsurance', false) ? 1 : 0
                    }
                    addTransaction(dispatch, lang, dictionary, addInsurancePayload)
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};
// submit to processing
//12
export const submitToProcessing = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'submitToProcess');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, { shipmentUuid: inputDTO.shipmentUuid }, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getShipmentData(dispatch, lang, dictionary, inputDTO, true);
                getShipmentProducts(dispatch, lang, dictionary, inputDTO);
                getTransactions(dispatch, lang, dictionary, inputDTO);
                getTransactionDetails(dispatch, lang, dictionary, inputDTO);
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// submit to TL processing
//12
export const submitToTLProcessing = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'submitToProcess');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, { shipmentUuid: inputDTO.shipmentUuid }, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
                utilCommon.showNotification(response.data.message, dictionary, lang);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// process payment
//13
export const processPayment = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    inputDTO.processPayment = true;
    const url = getApiUrl('shipment', 'processPayment');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, { shipmentUuid: inputDTO.shipmentUuid }, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getShipmentData(dispatch, lang, dictionary, inputDTO, true);
                getShipmentProducts(dispatch, lang, dictionary, inputDTO);
                getTransactionDetails(dispatch, lang, dictionary, inputDTO);
                getPayments(dispatch, lang, dictionary, inputDTO);
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)

                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }

                if (!lodash.isEmpty(lodash.get(response.data, 'content.insuranceResponse', ''))
                    && !lodash.get(response.data, 'content.insuranceResponse.status', true)) {
                    dispatch(actions.setManualPurchaseInsuranceNotification(lodash.get(response.data, 'content.insuranceResponse.msg')));
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
                if (response.data.content.data['3']) {
                    Object.keys(response.data.content.data['3']).map(x => {
                        if (response.data.content.data['3'][x].status) {

                            utilCommon.showNotification('Shipment payment with Card (' + response.data.content.data['3'][x].msg + ") successful", dictionary, lang)
                        } else {

                            utilCommon.showNotification('Shipment payment with Card (' + response.data.content.data['3'][x].msg + ") failed", dictionary, lang, true)
                        }
                    })
                }

                if (response.data.content.data['4']) {
                    if (response.data.content.data['4'].length > 0 && response.data.content.data['4'][0].status) {
                        utilCommon.showNotification("Shipment payment with check successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with check failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['5']) {
                    if (response.data.content.data['5'].length > 0 && response.data.content.data['5'][0].status) {
                        utilCommon.showNotification("Shipment payment with wire transfer successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with wire transfer failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['6']) {
                    if (response.data.content.data['6'].length > 0 && response.data.content.data['6'][0].status) {
                        utilCommon.showNotification("Shipment payment with automated clearing house (ACH) successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with automated clearing house (ACH) failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['7']) {
                    if (response.data.content.data['7'].length > 0 && response.data.content.data['7'][0].status) {
                        utilCommon.showNotification("Shipment payment with A/R account successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with A/R account failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['16']) {
                    if (response.data.content.data['16'].length > 0 && response.data.content.data['16'][0].status) {
                        utilCommon.showNotification("Shipment payment with Store Credit successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with Store Credit failed", dictionary, lang, true)
                    }
                }
                // getShipmentData(dispatch, lang, dictionary, inputDTO, true);
                getTransactionDetails(dispatch, lang, dictionary, inputDTO);
                getPayments(dispatch, lang, dictionary, inputDTO);
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)

                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            }

            getShipmentAlert(dispatch, lang, dictionary, inputDTO)

        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export const processTLPayment = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    inputDTO.processPayment = true;
    const url = getApiUrl('shipment', 'processPayment');
    dispatch(commonAction.setLoader(true))
    axios.post(url, { shipmentUuid: inputDTO.shipmentUuid }, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
                if (response.data.content.data['3']) {
                    Object.keys(response.data.content.data['3']).map(x => {
                        if (response.data.content.data['3'][x].status) {

                            utilCommon.showNotification('Shipment payment with Card (' + response.data.content.data['3'][x].msg + ") successful", dictionary, lang)
                        } else {

                            utilCommon.showNotification('Shipment payment with Card (' + response.data.content.data['3'][x].msg + ") failed", dictionary, lang, true)
                        }
                    })
                }

                if (response.data.content.data['4']) {
                    if (response.data.content.data['4'].length > 0 && response.data.content.data['4'][0].status) {
                        utilCommon.showNotification("Shipment payment with check successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with check failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['5']) {
                    if (response.data.content.data['5'].length > 0 && response.data.content.data['5'][0].status) {
                        utilCommon.showNotification("Shipment payment with wire transfer successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with wire transfer failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['6']) {
                    if (response.data.content.data['6'].length > 0 && response.data.content.data['6'][0].status) {
                        utilCommon.showNotification("Shipment payment with automated clearing house (ACH) successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with automated clearing house (ACH) failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['7']) {
                    if (response.data.content.data['7'].length > 0 && response.data.content.data['7'][0].status) {
                        utilCommon.showNotification("Shipment payment with A/R account successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with A/R account failed", dictionary, lang, true)
                    }
                }

                if (response.data.content.data['16']) {
                    if (response.data.content.data['16'].length > 0 && response.data.content.data['16'][0].status) {
                        utilCommon.showNotification("Shipment payment with Store Credit successful", dictionary, lang)
                    } else {
                        utilCommon.showNotification("Shipment payment with Store Credit failed", dictionary, lang, true)
                    }
                }
            }
            getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export const processTlCostAdjustment = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'processCostAdjustment');
    dispatch(commonAction.setLoader(true))

    axios.post(url, inputDTO, params).then((response) => {
            dispatch(commonAction.setLoader(false));

            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }

            getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentId);
        })
        .catch((err) => dispatch(commonAction.setLoader(false)));
};

export const processLtlCostAdjustment = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'processCostAdjustment');
    inputDTO.shipmentUuid = inputDTO.shipmentId;
    dispatch(commonAction.setLoader(true))

    axios.post(url, inputDTO, params).then((response) => {
        dispatch(commonAction.setLoader(false));

        if (response.data.success) {
            utilCommon.showNotification(response.data.message, dictionary, lang);

            getShipmentData(dispatch, lang, dictionary, inputDTO, true);
            getShipmentProducts(dispatch, lang, dictionary, inputDTO);
            getTransactionDetails(dispatch, lang, dictionary, inputDTO);
            getPayments(dispatch, lang, dictionary, inputDTO);
            getShipmentCharges(dispatch, lang, dictionary, inputDTO);
            getShipmentLogs(dispatch, lang, dictionary, {id: inputDTO.shipmentId});
            getShipmentAlert(dispatch, lang, dictionary, inputDTO);
        } else {
            response.data.errors.map((error) => {
                utilCommon.showNotification(error, dictionary, lang)
            })
        }
    })
        .catch(err => dispatch(commonAction.setLoader(false)));
};

// get document List
export const getDocuments = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'documents');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid + '&needCount=1'
    url = url + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber + '&sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch(actions.setDocuments(response.data.content.shipmentsDocuments, response.data.content.count, false));
                dispatch({
                    type: type.UPDATE_DOCUMENT_PAGING,
                    documentPageNumber: inputDTO.pageNumber,
                    documentPageSize: inputDTO.pageSize,
                    documentSortColumn: inputDTO.sortColumn,
                    documentSortOrder: inputDTO.sortOrder
                });
                dispatch(actions.setBolStatus(response.data.content.bolStatus));
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export const getInvoices = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'invoice');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid;
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            dispatch(actions.setDocumentInvoicesInfo(response.data));
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
}

// getBolStatusFromRedis
export const getBolStatusFromRedis = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'documentBol');
    url = url + '?shipmentIds=' + inputDTO.shipmentUuid;
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                const content = response.data.content[inputDTO.shipmentUuid];
                if (content && content.documentsGenerated !== inputDTO.documentsGenerated) {
                    dispatch(actions.setBolStatus(true, content.documentsGenerated));
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});
};

//add or update document
//14
export const addOrUpdateDocument = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'documents');

    dispatch(commonAction.setLoader(true))
    dispatch({ type: type.FILE_UPLOADED_COUNT, documentCount: inputDTO.documents.length })

    const getShipmentDataByMode = () => {
        if (inputDTO.hasOwnProperty('shipmentUuid') && inputDTO.hasOwnProperty('shippingModeId')) {
            if (inputDTO.shippingModeId === LTL_SHIPMENT_MODE) {
                getShipmentData(dispatch, lang, dictionary, {shipmentUuid: inputDTO.shipmentUuid})
            }

            if (inputDTO.shippingModeId === TL_SHIPMENT_MODE) {
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid)
            }
        }
    }

    inputDTO.documents.map((x, index) => {
        if (!x.shipmentDocumentUuid || x.savedDocumentFileName) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileAsBinaryString = e.target.result.split(',')[1]
                axios[x.shipmentDocumentUuid ? 'put' : 'post'](url, { ...x, documentFileContents: fileAsBinaryString }, params)
                    .then((response) => {
                        dispatch(commonAction.setLoader(false))
                        if (response.data.success) {
                            utilCommon.showNotification(response.data.message, dictionary, lang);
                            getDocuments(dispatch, lang, dictionary, { shipmentUuid: x.shipmentUuid, pageNumber: 1, pageSize: 20, sortColumn: 'modifiedDate', sortOrder: 'desc' })
                            getInvoices(dispatch, lang, dictionary, { shipmentUuid: x.shipmentUuid })
                            dispatch({ type: type.FILE_UPLOADED, uploadedflag: true, uploadedIndex: index })
                            if(x.shipmentUuid) {
                                getShipmentLogs (dispatch, lang, dictionary, {id: x.shipmentUuid})
                            }

                            /*
                                Get shipment data after the last document uploaded
                             */
                            if (index === (inputDTO.documents.length - 1)) {
                                getShipmentDataByMode()
                            }
                        } else {
                            dispatch({ type: type.FILE_UPLOADED, uploadedflag: false, uploadedIndex: index })
                            response.data.errors.map((error) => {
                                utilCommon.showNotification(error, dictionary, lang)
                            })
                        }
                    })
                    .catch((err) => {
                        dispatch({ type: type.FILE_UPLOADED, uploadedflag: false, uploadedIndex: index })

                        dispatch(commonAction.setLoader(false))
                    });
            };

            reader.readAsDataURL(x.documentFileContents);
        } else {
            axios[x.shipmentDocumentUuid ? 'put' : 'post'](url, { ...x }, params)
                .then((response) => {
                    dispatch(commonAction.setLoader(false))
                    if (response.data.success) {
                        utilCommon.showNotification(response.data.message, dictionary, lang);
                        getDocuments(dispatch, lang, dictionary, { shipmentUuid: x.shipmentUuid, pageNumber: 1, pageSize: 20, sortColumn: 'modifiedDate', sortOrder: 'desc' })
                        dispatch({ type: type.FILE_UPLOADED, uploadedflag: true, uploadedIndex: index })
                        if(x.shipmentUuid) {
                            getShipmentLogs (dispatch, lang, dictionary, {id: x.shipmentUuid})
                        }

                    } else {
                        dispatch({ type: type.FILE_UPLOADED, uploadedflag: false, uploadedIndex: index })
                        response.data.errors.map((error) => {
                            utilCommon.showNotification(error, dictionary, lang)
                        })
                    }
                })
                .catch((err) => {
                    dispatch({ type: type.FILE_UPLOADED, uploadedflag: false, uploadedIndex: index })

                    dispatch(commonAction.setLoader(false))
                });
        }

    })
};
// send documentt to email
export const sendDocumentsToEmail = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'documentEmail');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);

                if (inputDTO.shipmentUuid) {
                    if (lodash.get(inputDTO, 'shippingModeId.id') === TL_SHIPMENT_MODE) {
                        getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
                    } else {
                        getShipmentLogs(dispatch, lang, dictionary, {id: inputDTO.shipmentUuid});
                    }
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// download documents
export const downloadDocuments = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'documentPreview');
    inputDTO.shipmentDocumentUuid.map((x, index) => {
        dispatch(commonAction.setLoader(true))
        let url1 = url + `?shipmentDocumentUuid=${x}&isDownload=${true}`
        if (inputDTO.documentsGenerated !== undefined) {
          url1 += `&documentsGenerated=${inputDTO.documentsGenerated}`;
        }
        axios['get'](url1, params)
            .then((response) => {
                dispatch(commonAction.setLoader(false));
                if (response.data.success) {
                    var binary = atob(response.data.content.fileContent.replace(/\s/g, ''));
                    var len = binary.length;
                    var buffer = new ArrayBuffer(len);
                    var view = new Uint8Array(buffer);
                    for (var i = 0; i < len; i++) {
                        view[i] = binary.charCodeAt(i);
                    }

                    let data = 'data:' + response.data.content.documentMimeType + ';base64,' + response.data.content.fileContent
                    var blob = new Blob([view], { type: response.data.content.documentMimeType });
                    saveAs(blob, response.data.content.shipmentUuid + '_' + response.data.content.documentFileName);

                    if (lodash.has(inputDTO, 'shipmentUuid')) {
                        if (lodash.get(inputDTO, 'shippingModeId.id') === TL_SHIPMENT_MODE) {
                            getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
                        } else {
                            getShipmentLogs(dispatch, lang, dictionary, {id: inputDTO.shipmentUuid});
                        }
                    }

                    utilCommon.showNotification(response.data.message, dictionary, lang);

                } else {
                    response.data.errors.map((error) => {
                        utilCommon.showNotification(error, dictionary, lang)
                    })
                }
            })
            .catch((err) => {
                dispatch(commonAction.setLoader(false))
            });
    })
};

// preview documents

export const previewDocument = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'documentPreview');
    dispatch(commonAction.setLoader(true))
    url = url + `?shipmentDocumentUuid=${inputDTO.shipmentDocumentUuid}`
    axios['get'](url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false));
            if (response.data.success) {
                let data = 'data:' + response.data.content.documentMimeType + ';base64,' + response.data.content.fileContent;
                dispatch({ type: type.SET_PREVIEW_CONTENT, previewContent: data, previewFileName: response.data.content.documentFileName })
                utilCommon.showNotification(response.data.message, dictionary, lang);

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });



};

//delete Document
//15
export const deleteDocument = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'documents');
    // url = url + `?shipmentDocumentUuid=${inputDTO.shipmentDocumentUuid}`
    dispatch(commonAction.setLoader(true))
    axios['patch'](url, { shipmentDocumentUuid: inputDTO.shipmentDocumentUuid }, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false));
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getDocuments(dispatch, lang, dictionary, { shipmentUuid: inputDTO.shipmentUuid, pageNumber: 1, pageSize: 20, sortColumn: 'modifiedDate', sortOrder: 'desc' })
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });



};

//fetch documents
export const fetchDocument = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'fetchDocument');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false));
            if (response.data.success) {
                getDocuments(dispatch, lang, dictionary, { shipmentUuid: inputDTO.shipmentUuid, pageNumber: 1, pageSize: 20, sortColumn: 'modifiedDate', sortOrder: 'desc' })
                utilCommon.showNotification('20095', dictionary, lang);

            } else {
                utilCommon.showNotification(99995, dictionary, lang);
                // response.data.errors.map((error) => {

                //     utilCommon.showNotification(error, dictionary, lang)
                // })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });



};

// shipment charges

export const getShipmentCharges = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'shipment');
    dispatch(commonAction.setLoader(true))
    url = url + "?shipmentUuid=" + inputDTO.shipmentUuid + '&maintainHash=1'
    axios['get'](url, params)
        .then((response) => {
            if (!lodash.get(inputDTO, 'addInsurance', false)) dispatch(commonAction.setLoader(false));
            if (response.data.success) {
                dispatch({ type: type.SET_SHIPMENT_CHARGES, shipmentCharges: response.data.content.shipmentList[0]})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        }).catch((err) => {dispatch(commonAction.setLoader(false))});
};

//update custom info
//16
export const updateCustomInfo = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    inputDTO.updateCustomInfo = true;
    let url = getApiUrl('shipment', 'customBroker');
    dispatch(actions.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(actions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getShipmentData(dispatch, lang, dictionary, { shipmentUuid: inputDTO.shipmentUuid, firstTime: true })
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(actions.setLoader(false))
        });
};

//delete custom info
//17
export const deleteCustomInfo = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'customBroker');
    dispatch(actions.setLoader(true))
    axios['patch'](url, { shipmentUuid: inputDTO.shipmentUuid }, params)
        .then((response) => {
            dispatch(actions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getShipmentData(dispatch, lang, dictionary, { shipmentUuid: inputDTO.shipmentUuid, firstTime: true })
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(actions.setLoader(false))
        });



};

//cancel shipment
//18
export const cancelShipment = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'cancelShipment');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getShipmentData(dispatch, lang, dictionary, inputDTO, true,true)
                getTransactionDetails(dispatch, lang, dictionary, inputDTO)
                getPayments(dispatch, lang, dictionary, inputDTO)
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};


export const cancelRefundShipment = (dispatch, lang, dictionary, inputDTO, callback = null) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'cancelRefundShipment');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (response.data.data.cancelShipment.success){
                    utilCommon.showNotification(response.data.data.cancelShipment.message, dictionary, lang);
                }else{
                    response.data.data.cancelShipment.errors.map((error) => {
                        utilCommon.showNotification(error, dictionary, lang)
                    })
                }
                if (response.data.data.addRefund.success){
                    utilCommon.showNotification(response.data.data.addRefund.message, dictionary, lang);
                }else{
                    response.data.data.addRefund.errors.map((error) => {
                        utilCommon.showNotification(error, dictionary, lang)
                    })
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
            if (callback){
                callback();
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};



//cancel tl shipment
export const cancelTLShipment = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'cancelShipment');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showAgentNotification('Shipment is cancelled successfully', 3);
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

//get tracking info
export const getTrackingInfo = (dispatch, lang, dictionary, inputDTO, options = {}) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'tracking');
    dispatch(commonAction.setLoader(true))
    url = url + `?shipmentUuid=${inputDTO.shipmentUuid}`
    axios['get'](url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false));
            if (response.data.success) {
                if (response.data.content.shipmentTrackingInfo.length > 0) {
                    dispatch({ type: type.SET_TRACKING_HEADER, trackingHeader: response.data.content.shipmentTrackingInfo[0] })
                    getTrackingDetails(
                        dispatch,
                        lang,
                        dictionary,
                        {
                            shipmentTrackingUuid: response.data.content.shipmentTrackingInfo[0].shipmentTrackingUuid
                        },
                        options
                    )
                }

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get tracking details

export const getTrackingDetails = (dispatch, lang, dictionary, inputDTO, options = {}) => {
    const {
        sortColumn = 'status_date_time',
        sortOrder = 'asc'
    } = options;
    const queryString = utilCommon.convertObjectToQueryString({
        sortColumn,
        sortOrder,
        shipmentTrackingUuid: inputDTO.shipmentTrackingUuid,
    })
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'trackingDetails');
    dispatch(commonAction.setLoader(true))
    url = url + `?${queryString}`
    axios['get'](url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false));
            if (response.data.success) {
                dispatch({ type: type.SET_TRACKING_INFO, trackingInfoList: response.data.content.shipmentTrackingDetails })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// post tracking info
//19
export const postTrackingInfo = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'tracking');
    dispatch(actions.setLoader(true))
    axios['post'](url, { shipmentUuid: inputDTO.shipmentUuid }, params)
        .then((response) => {
            dispatch(actions.setLoader(false))
            if (response.data.success) {
                // utilCommon.showNotification(response.data.message, dictionary, lang);
                getTrackingInfo(dispatch, lang, dictionary, { shipmentUuid: inputDTO.shipmentUuid })
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(actions.setLoader(false))
        });



};
// book shipmkent

//20
export const bookShipment = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'bookShipment');
    dispatch(commonAction.setLoader(true))
    return axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getShipmentData(dispatch, lang, dictionary, { shipmentUuid: inputDTO.shipmentUuid, firstTime: true }, true);
                getTransactionDetails(dispatch, lang, dictionary, inputDTO);
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }

                if (!lodash.isEmpty(lodash.get(response.data, 'content.insuranceResponse', ''))
                    && !lodash.get(response.data, 'content.insuranceResponse.status', true)) {
                    dispatch(actions.setManualPurchaseInsuranceNotification(lodash.get(response.data, 'content.insuranceResponse.msg')));
                }
            } else {
                let flagToOpen = true;
                response.data.errors.map((error) => {
                    if(Array.isArray(error)){
                        error.map((y,index)=>{
                            utilCommon.showNotification(y, dictionary, lang);
                            flagToOpen = false;
                        })

                    }
                    else{
                        utilCommon.showNotification(error, dictionary, lang)
                    }

                })
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
                if (response.data.content && response.data.content.responseData && response.data.content.responseData.errorMessage) {
                    utilCommon.showNotification(response.data.content.responseData.errorMessage, dictionary, lang, true)
                }
                if(flagToOpen){
                    dispatch({ type: type.BOOK_SHIPMENT_MANUALLY, flag: true })
                }

            }
            return response;
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get calculated valuies for cancel shipment form
export const getCalculatedRefund = (dispatch, lang, dictionary, inputDTO) => {
    const queryParams = utilCommon.convertObjectToQueryString({
        paymentStatus: 1,
    });

    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'calcuateRefund');
    dispatch(commonAction.setLoader(true))
    axios['post'](url + `?${queryParams}`, { shipmentUuid: inputDTO.shipmentUuid }, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_CANCELLATION_FORM_DETAILS, cancellationDetails: response.data.content })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

// get contracts
export const getContracts = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url1 = getApiUrl('quote', 'getContracts') + '?pageSize=1000&pageNumber=1';
    dispatch(commonAction.setLoader(true))
    axios.get(url1, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_CONTRACTS, contracts: response.data.content.contractList })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
}


// get contracts
export const getContractCarrierDetails = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url1 = getApiUrl('carrier', 'getCarriers') + '?carrierUuid=' + inputDTO.carrierUuid
    dispatch(commonAction.setLoader(true))
    axios.get(url1, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.content.carrierList) {
                dispatch({ type: type.SET_CARRIER_DETAILS, carrierDetails: lodash.find(response.data.content.carrierList, inputDTO) })
            } else {
                dispatch({ type: type.SET_CARRIER_DETAILS, carrierDetails: {} })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
            dispatch({ type: type.SET_CARRIER_DETAILS, carrierDetails: {} })
        });
}

// get contracts
export const getFalveyTermsFlag = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url1 = getApiUrl('shipment', 'falvey') + '?customerUuid=' + inputDTO.customerUuid
    dispatch(commonAction.setLoader(true))
    axios.get(url1, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.content) {
                dispatch({ type: type.SET_FALVEY, falveyDetails: response.data.content})
            } else {

            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))

        });
}

// get contracts
export const getTermsAndConditionFlag = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url1 = getApiUrl('shipment', 'termCondition') + '?customerUuid=' + inputDTO.customerUuid+'&skipFileContent=1'
    dispatch(commonAction.setLoader(true))
    axios.get(url1, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.content) {
                dispatch({ type: type.SET_TERMS, termsCondition: response.data.content})
            } else {

            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))

        });
}



// change LTl to manual
//21
export const changeLTLToManual = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'covertShipment');

    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                getShipmentData(dispatch, lang, dictionary, inputDTO, true)
                getTransactionDetails(dispatch, lang, dictionary, inputDTO)
                getPayments(dispatch, lang, dictionary, inputDTO)
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)
                utilCommon.showNotification("convert shipment successful", dictionary, lang)
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
}
// get Tasks list
export const getTasks = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'task');
    let sortingPart = lodash.get(inputDTO, 'sortingCall', false)
        ? '&sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder
        : '&sortColumn=dueDateTime&sortOrder=asc';
    url += '?customerUuid=' + inputDTO.customerUuid + '&shipmentUuid=' + inputDTO.shipmentUuid
        + '&pageSize=500' + '&pageNumber=' + 1
        + sortingPart + '&taskStatus=[1,4]';
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch(actions.setTasks(response.data.content.userTaskList, response.data.content.count, false))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {

            dispatch(commonAction.setLoader(false))
        });
};

export const deleteTask = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()}
    const url = getApiUrl('customer', 'task')
    dispatch(commonAction.setLoader(true))
    axios.patch(url, {userTaskUuid: inputDTO.userTaskUuid, taskStatus: {id: 0, name: 'Deleted'}}, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Task is deleted successfully.', dictionary, lang)
                getTasks(dispatch, lang, dictionary, {
                    customerUuid: inputDTO.customerUuid,
                    shipmentUuid: inputDTO.shipmentUuid
                })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
}

export const reconcileTask = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('customer', 'task');
    dispatch(commonAction.setLoader(true))
    axios['patch'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                if (inputDTO.userTaskUuid) {
                    getTasks(dispatch, lang, dictionary, {...inputDTO, sortColumn: 'dueDateTime', 'sortOrder': 'asc'})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export const processRefund = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'processRefund');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                // getShipmentData(dispatch, lang, dictionary, inputDTO, true)
                getTransactionDetails(dispatch, lang, dictionary, inputDTO)
                getPayments(dispatch, lang, dictionary, inputDTO)
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)
                utilCommon.showNotification("Refund process successful", dictionary, lang)
                if(inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
}

export const processTLRefund = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'processRefund');
    dispatch(commonAction.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
                utilCommon.showNotification("Refund process successful", dictionary, lang)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
}

export const deleteShipmentCharge = (dispatch, lang, dictionary, inputDTO) => {
    inputDTO.quoteId = inputDTO.shipmentUuid;
    const params = { headers: authHeader() };
    const url = getApiUrl('adminTools', 'deleteShipmentCharge');
    dispatch(commonAction.setLoader(true))
    axios['put'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Shipment transaction deleted successfully.', dictionary, lang)
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)
                getTransactionDetails(dispatch, lang, dictionary, inputDTO)
                getTransactions(dispatch, lang, dictionary, inputDTO)
                if (inputDTO.shipmentUuid) {
                    getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch(
            (err) => {
                dispatch(commonAction.setLoader(false))
            });
};

export const postDatcom = (inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'loadPostingDatCom');

    return axios.post(url, inputDTO, params)
        .then((response) => {
            let error = lodash.has(response, 'data.success') && lodash.get(response, 'data.success') === false || lodash.has(response, 'data.code') && lodash.get(response, 'data.code') !== 200;

            if (!error) {
                utilCommon.showAgentNotification('The shipment has been posted to DAT', 3);
            } else {
                let message = 'The shipment has not been posted to DAT';
                let errors = lodash.get(response, 'data.errors', {})

                Object.keys(errors).map(function(key) {
                    message += "<br/>" + errors[key];
                });

                utilCommon.showAgentNotification(message, 1);
            }
        });
}

export const postTruckstop = (inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'loadPostingTruckstop');

    return axios.post(url, inputDTO, params)
        .then((response) => {
            let error = lodash.has(response, 'data.success') && lodash.get(response, 'data.success') === false || lodash.has(response, 'data.code') && lodash.get(response, 'data.code') !== 200;

            if (!error) {
                utilCommon.showAgentNotification('The shipment has been posted to Truckstop', 3);
            } else {
                let message = 'The shipment has not been posted to Truckstop';
                let errors = lodash.get(response, 'data.errors', {})

                Object.keys(errors).map(function(key) {
                    message += "<br/>" + errors[key];
                });

                utilCommon.showAgentNotification(message, 1);
            }
        });
}

export const putDatcom = (datcomId, inputDTO) => {
    const params = {headers: authHeader()};
    const url = `${getApiUrl('shipment', 'loadPostingDatCom')}/${datcomId}`;

    return axios.put(url, inputDTO, params)
        .then((response) => {
            let error = lodash.has(response, 'data.success') && lodash.get(response, 'data.success') === false || lodash.has(response, 'data.code') && lodash.get(response, 'data.code') !== 200;

            if (!error) {
                utilCommon.showAgentNotification('The shipment has been updated at DAT', 3);
            } else {
                let message = 'The shipment has not been updated at DAT';
                let errors = lodash.get(response, 'data.errors', {})

                Object.keys(errors).map(function(key) {
                    message += "<br/>" + errors[key];
                });

                utilCommon.showAgentNotification(message, 1);
            }
        });
}

export const putTruckstop = (truckstopId, inputDTO) => {
    const params = {headers: authHeader()};
    const url = `${getApiUrl('shipment', 'loadPostingTruckstop')}/${truckstopId}`;

    return axios.put(url, inputDTO, params)
        .then((response) => {
            let error = lodash.has(response, 'data.success') && lodash.get(response, 'data.success') === false || lodash.has(response, 'data.code') && lodash.get(response, 'data.code') !== 200;

            if (!error) {
                utilCommon.showAgentNotification('The shipment has been updated at Truckstop', 3);
            } else {
                let message = 'The shipment has not been updated at Truckstop';
                let errors = lodash.get(response, 'data.errors', {})

                Object.keys(errors).map(function(key) {
                    message += "<br/>" + errors[key];
                });

                utilCommon.showAgentNotification(message, 1);
            }
        });
}

export const deleteDatcom = (datcomId) => {
    const params = {headers: authHeader()};
    const url = `${getApiUrl('shipment', 'loadPostingDatCom')}/${datcomId}`;

    return axios.delete(url, params)
        .then((response) => {
            if (lodash.get(response, 'data.success')) {
                utilCommon.showAgentNotification('The shipment has been deleted from Datcom', 3);
            } else {
                utilCommon.showAgentNotification('The shipment has not been deleted from Datcom', 1);
            }
        });
}

export const deleteTruckstop = (truckstopId) => {
    const params = {headers: authHeader()};
    const url = `${getApiUrl('shipment', 'loadPostingTruckstop')}/${truckstopId}`;

    return axios.delete(url, params)
        .then((response) => {
            if (lodash.get(response, 'data.success')) {
                utilCommon.showAgentNotification('The shipment has been deleted from Truckstop', 3);
            } else {
                utilCommon.showAgentNotification('The shipment has not been deleted from Truckstop', 1);
            }
        });
}


// add  product
export const addTLAddress = (dispatch, lang, dictionary, inputDTO, index, type, shipmentUuid) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'tlAddress');

    dispatch(commonAction.setLoader(true))

    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if ( type === 1){
                    utilCommon.showNotification('Shipment pickup address save successfully.', dictionary, lang)
                }else if (type === 2){
                    utilCommon.showNotification('Shipment drop address save successfully.', dictionary, lang)
                }
                getTLShipment(dispatch, lang, dictionary, shipmentUuid);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export const deleteTLAddress = (dispatch, lang, dictionary, item, shipmentUuid) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'tlAddress');

    dispatch(commonAction.setLoader(true))

    axios.delete(`${url}/${item.id}`,  params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                if (item.type === 1){
                    // dispatch(actions.setTLPickupAddresses(newItems));
                    utilCommon.showNotification('Shipment pickup address deleted successfully.', dictionary, lang)
                }else{
                    // dispatch(actions.setTLDropAddresses(newItems));
                    utilCommon.showNotification('Shipment drop address deleted successfully.', dictionary, lang)
                }
                getTLShipment(dispatch, lang, dictionary, shipmentUuid);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};


export const getTLShipment = (dispatch, lang, dictionary, id) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'tlShipment');

    dispatch(commonAction.setLoader(true))

    axios.get(`${url}/${id}`,  params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                dispatch(actions.setCurrentShipment(response.data.data))
                dispatch(actions.setShipmentProducts(prettifyProductsHazmatValues(response.data.data.products), response.data.data.products.length, false));
                dispatch(actions.setTransactionDetails({
                    totalCharge: Number( response.data.data.transactionsDetailSummary.totalCharge ),
                    totalCost: Number( response.data.data.transactionsDetailSummary.totalCost ),
                    transactionDetails: response.data.data.transactionsDetail.map( transaction => {
                        return {
                            totalTransTypeCharge: Number(transaction.totalCharge),
                            transactionTypeId:{
                                id: transaction.id,
                                name: transaction.name
                            }
                        };
                    })
                }));
                dispatch({ type: type.SET_SHIPMENT_CHARGES, shipmentCharges: response.data.data })
                //shipmentPayment: response.data.data.shipmentPayment,
                dispatch(actions.setShipmentPaymentDetails(
                    response.data.data.payments,
                    Number( response.data.data.transactionsDetailSummary.totalCharge )
                ))
                getTasks(dispatch, lang, dictionary, {
                    customerUuid: response.data.data.customerUuid.id,
                    shipmentUuid: id
                })
                getShipmentAlert(dispatch, lang, dictionary, {shipmentUuid: id})
                getCancellationPayments(dispatch, lang, dictionary, id)
                getNotes(dispatch, lang, dictionary, { shipmentUuid: id });
                customerService.getARPaymentOptions(dispatch, lang, dictionary, {customerUuid: response.data.data.customerUuid.id})
                customerService.getPaymentOptions(dispatch, lang, dictionary, { customerUuid: response.data.data.customerUuid.id, permFlag:true })
                customerService.getCustomerData(dispatch, lang, dictionary, { customerUuid: response.data.data.customerUuid.id ,permFlag:true  })
                customerService.getAddresses(dispatch, lang, dictionary, {
                    customerUuid: response.data.data.customerUuid.id,
                    sortColumn: 'addedDate',
                    sortOrder: 'desc',
                    pageSize: 10,
                    pageNumber: 1
                });
                storeCreditService.getCustomerStoreCreditBalance(dispatch, response.data.data.customerUuid.id)
                getProducts(dispatch, lang, dictionary, {
                    customerUuid: response.data.data.customerUuid.id,
                });
                getTlTrackingData(dispatch, lang, dictionary, {shipmentUuid: id})
                dispatch({ type: type.SET_CARRIERS, carriers: response.data.data.tlShipmentData.carrier })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};


export const getShipmentMode = (dispatch, lang, dictionary, shipmentId) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('shipment', 'getMode');

    dispatch(commonAction.setLoader(true))

    return axios.get(`${url}/${shipmentId}`, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                return response.data.data;
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
};

export const deleteTLShipmentCharge = (dispatch, lang, dictionary, inputDTO) => {
    inputDTO.quoteId = inputDTO.shipmentUuid;
    const params = { headers: authHeader() };
    const url = getApiUrl('adminTools', 'deleteShipmentCharge');
    dispatch(commonAction.setLoader(true))
    axios['put'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Shipment transaction deleted successfully.', dictionary, lang)
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch(
            (err) => {
                dispatch(commonAction.setLoader(false))
            });
};

export const invalidateShouldRefreshFlag = (shipmentId) => {
    axios.get(
        (getApiUrl('shipment', 'invalidateShouldRefreshFlag') + `/${shipmentId}`),
        {headers: authHeader()}
    );
}

export const reassignTransactions = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'reassignTransactions');
    dispatch(commonAction.setLoader(true));

    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Transactions reassigned success', dictionary, lang)
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => dispatch(commonAction.setLoader(false)));
}

export const flipShipmentMode = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'flipShipmentMode');

    dispatch(commonAction.setLoader(true));

    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Shipment mode flipped success.', dictionary, lang)
                window.location.reload();
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => dispatch(commonAction.setLoader(false)));
}

export const processPaymentAndBook = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    inputDTO.processPayment = true;
    const url = getApiUrl('shipment', 'processPaymentAndBook');
    dispatch(commonAction.setLoader(true))
    dispatch(actions.setProcessingPaymentAndBookStatus(true))
    axios['post'](url, {shipmentUuid: inputDTO.shipmentUuid}, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            dispatch(actions.setProcessingPaymentAndBookStatus(false))
            storeCreditService.getCustomerStoreCreditBalance(dispatch, inputDTO.customerUuid)
            customerService.getARPaymentOptions(dispatch, lang, dictionary, {customerUuid: inputDTO.customerUuid})
            delete inputDTO.customerUuid

            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
                getShipmentData(dispatch, lang, dictionary, inputDTO, true);
                getShipmentProducts(dispatch, lang, dictionary, inputDTO);
                getTransactions(dispatch, lang, dictionary, inputDTO);
                getTransactionDetails(dispatch, lang, dictionary, inputDTO);
                getPayments(dispatch, lang, dictionary, inputDTO);
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)

                if (inputDTO.shipmentUuid) {
                    getShipmentLogs(dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }

                if (!lodash.isEmpty(lodash.get(response.data, 'content.insuranceResponse', ''))
                    && !lodash.get(response.data, 'content.insuranceResponse.status', true)) {
                    dispatch(actions.setManualPurchaseInsuranceNotification(lodash.get(response.data, 'content.insuranceResponse.msg')));
                }
            } else {
                let flagToOpen = !lodash.get(response.data, 'paymentError', false);
                response.data.errors.map((error) => {
                    if (Array.isArray(error)) {
                        error.map((y, index) => {
                            utilCommon.showNotification(y, dictionary, lang);
                            flagToOpen = false;
                        })

                    } else {
                        utilCommon.showNotification(error, dictionary, lang)
                    }
                })

                if (flagToOpen) {
                    dispatch({type: type.BOOK_SHIPMENT_MANUALLY, flag: true})
                }

                if (response.data.content && response.data.content.responseData && response.data.content.responseData.errorMessage) {
                    utilCommon.showNotification(response.data.content.responseData.errorMessage, dictionary, lang, true)
                }

                getShipmentData(dispatch, lang, dictionary, inputDTO, true);
                getTransactions(dispatch, lang, dictionary, inputDTO);
                getTransactionDetails(dispatch, lang, dictionary, inputDTO);
                getPayments(dispatch, lang, dictionary, inputDTO);
                getShipmentCharges(dispatch, lang, dictionary, inputDTO)

                if (inputDTO.shipmentUuid) {
                    getShipmentLogs(dispatch, lang, dictionary, {id: inputDTO.shipmentUuid})
                }
            }

            getShipmentAlert(dispatch, lang, dictionary, inputDTO)

        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
            dispatch(actions.setProcessingPaymentAndBookStatus(false))
        });
};

export const getCancellationPayments = (dispatch, lang, dictionary, shipmentId) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('shipment', 'cancellationInfo');

    axios.get(`${url}/${shipmentId}`, params)
        .then((response) => {
            dispatch(actions.setCancellationInfo( response.data.success
                ? response.data.data
                : {}
            ));
        })
        .catch((err) => {});
};

export const createTripEvent = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'createTripEvents');

    dispatch(commonAction.setLoader(true));

    axios.post(url, inputDTO, params)
    .then((response) => {
        dispatch(commonAction.setLoader(false))

        if (response.data.success) {
            utilCommon.showNotification('Trip Event created success', dictionary, lang);
            getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
        } else {
            response.data.errors.map((error) => {
                utilCommon.showNotification(error, dictionary, lang)
            })
        }
    })
    .catch((err) => {
        dispatch(commonAction.setLoader(false));
        utilCommon.showAgentNotification('Something went wrong during create Trip Event', 4);
    });
}

export const createLocationUpdate = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'createLocationUpdates');

    dispatch(commonAction.setLoader(true));

    axios.post(url, inputDTO, params)
    .then((response) => {
        dispatch(commonAction.setLoader(false))

        if (response.data.success) {
            utilCommon.showNotification('Location Update created success', dictionary, lang);
            getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
        } else {
            response.data.errors.map((error) => {
                utilCommon.showNotification(error, dictionary, lang)
            })
        }
    })
    .catch((err) => {
        dispatch(commonAction.setLoader(false));
        utilCommon.showAgentNotification('Something went wrong during create Location Update', 4);
    });
}

export const uploadProductImage = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'uploadProductImage');
    dispatch(commonAction.setLoader(true))
    dispatch({ type: type.FILE_UPLOADED_COUNT, documentCount: 1 })
    const reader = new FileReader();
    reader.onload = (e) => {
        const fileAsBinaryString = e.target.result.split(',')[1];
        const data = {
            shipmentUuid: inputDTO.shipmentUuid,
            shipmentProductUuid: inputDTO.shipmentProductUuid,
            shipmentSource: 2,
            name: inputDTO.image.name,
            type: inputDTO.image.type,
            imageContent: fileAsBinaryString
        }
        axios.post(url, data, params)
            .then((response) => {
                dispatch(commonAction.setLoader(false))
                if (response.data.success) {
                    utilCommon.showNotification('Product image uploaded successfully', dictionary, lang);
                    getDocuments(dispatch, lang, dictionary,
                        {shipmentUuid: inputDTO.shipmentUuid, pageNumber: 1, pageSize: 20, sortColumn: 'modifiedDate', sortOrder: 'desc' });
                    dispatch({ type: type.FILE_UPLOADED, uploadedflag: true, uploadedIndex: 0 })
                    if (inputDTO.shipmentUuid) {
                        getShipmentLogs (dispatch, lang, dictionary, {id: inputDTO.shipmentUuid});
                    }
                } else {
                    dispatch({ type: type.FILE_UPLOADED, uploadedflag: false, uploadedIndex: 0 });
                    response.data.errors.map((error) => {
                        utilCommon.showNotification(error, dictionary, lang);
                    })
                }
            })
            .catch((err) => {
                dispatch({ type: type.FILE_UPLOADED, uploadedflag: false, uploadedIndex: 0 });
                dispatch(commonAction.setLoader(false));
            });
    };

    reader.readAsDataURL(inputDTO.image);
};

export default {
    getShipments,
    getTeams,
    getAddresses,
    getProducts,
    getShipmentProducts,
    addProduct,
    deleteProduct,
    updateProductInfo,
    getMasterData,
    getShipmentData,
    updateShipmentInfo,
    pinOrUnpinNote,
    addNote,
    getNotes,
    resassignShipments,
    getShipmentAlert,
    addOrUpdateAlert,
    deactivateShipment,
    activateShipment,
    makeShipmentPayment,
    getTransactionLogs,
    getTransactions,
    reassignTransaction,
    repeatShipment,
    validateCouponCode,
    addTransaction,
    getTransactionDetails,
    getPayments,
    validateInsurance,
    cancelInsurance,
    submitToProcessing,
    processPayment,
    getDocuments,
    addOrUpdateDocument,
    sendDocumentsToEmail,
    previewDocument,
    downloadDocuments,
    deleteDocument,
    getShipmentCharges,
    updateCustomInfo,
    deleteCustomInfo,
    cancelShipment,
    cancelRefundShipment,
    getTrackingInfo,
    getTrackingDetails,
    postTrackingInfo,
    fetchDocument,
    bookShipment,
    getCalculatedRefund,
    getContracts,
    changeLTLToManual,
    getContractCarrierDetails,
    getFalveyTermsFlag,
    getTermsAndConditionFlag,
    getTasks,
    getShipmentLogs,
    processRefund,
    getBolStatusFromRedis,
    deleteShipmentCharge,
    getInvoices,
    postDatcom,
    putDatcom,
    deleteDatcom,
    postTruckstop,
    putTruckstop,
    deleteTruckstop,
    addTLAddress,
    deleteTLAddress,
    getTLShipment,
    addTLProduct,
    deleteTLProduct,
    getShipmentMode,
    addTLTransaction,
    deleteTLShipmentCharge,
    submitToTLProcessing,
    processTLPayment,
    processTLRefund,
    cancelTLShipment,
    invalidateShouldRefreshFlag,
    reassignTransactions,
    flipShipmentMode,
    processTlCostAdjustment,
    processLtlCostAdjustment,
    processPaymentAndBook,
    getCancellationPayments,
    createTripEvent,
    createLocationUpdate,
    uploadProductImage,
    deleteTask,
    reconcileTask
};
